import TYPES from '@/types';
import Vue from 'vue';
import i18n from '@/vue-app/plugins/i18n';
import { v4 as uuid } from 'uuid';
import { requiredRule, requiredRuleByKey } from '@/vue-app/utils/form-rules';
import { convertToLargerPossibleUnit } from '@/vue-app/utils/files';

// Infrastructure
import Functions from '@/modules/shared/infrastructure/utils/functions';

// Application
import {
  SearchAddressQuery,
} from '@/modules/my-investment/catalogs/kuspit/address/application/queries';
import {
  SearchDocumentTypesQuery,
} from '@/modules/my-investment/catalogs/allianz/document-type/application/queries';
import { SearchOnBoardingStepsQuery }
  from '@/modules/my-investment/on-boarding-steps/application/queries';
import { SearchInvestmentProvidersQuery }
  from '@/modules/my-investment/investment-provider/application/queries';
import {
  CreateOnBoardingStepCommand,
  UpdateOnBoardingStepCommand,
} from '@/modules/my-investment/on-boarding-steps/application/commands';
import {
  UploadFileProofOfAddressCommand,
} from '@/modules/my-investment/upload-file/application/commands';
import {
  SearchCustomerDocumentsQuery,
} from '@/modules/my-investment/customer-documents/application/queries';
import { GetFileQuery } from '@/modules/my-investment/file/application/queries';

// Domain
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import {
  DocumentTypeEntity,
} from '@/modules/my-investment/catalogs/allianz/document-type/domain/entities/document-type-entity';
import {
  CustomerDocumentEntity,
} from '@/modules/my-investment/customer-documents/domain/entities/customer-document-entity';
import { FileEntity } from '@/modules/my-investment/file/domain/entities/file-entity';
import { FileDto } from '@/modules/my-investment/upload-file/domain/dtos/file-dto';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class ContractSavingsPersonalInformationProofOfAddressViewModel {
  @Inject(TYPES.SEARCH_CATALOG_ALLIANZ_DOCUMENT_TYPE_QUERY)
  private readonly searchDocumentTypesQuery!: SearchDocumentTypesQuery;

  @Inject(TYPES.SEARCH_CATALOG_KUSPIT_ADDRESS_QUERY)
  private readonly searchAddressQuery!: SearchAddressQuery;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  private readonly searchOnBoardingStepsQuery!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  private readonly searchInvestmentProvidersQuery!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly createOnBoardingStepCommand!: CreateOnBoardingStepCommand;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly updateOnBoardingStepCommand!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.MY_INVESTMENT_UPLOAD_FILE_PROOF_OF_ADDRESS_COMMAND)
  private readonly uploadFileProofOfAddressCommand!: UploadFileProofOfAddressCommand;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_CUSTOMER_DOCUMENTS_QUERY)
  private readonly search_customer_documents_query!: SearchCustomerDocumentsQuery;

  @Inject(TYPES.MY_INVESTMENT_GET_FILE_QUERY)
  private readonly get_file_query!: GetFileQuery;

  @Inject(TYPES.UTIL_FUNCTIONS)
  readonly functions!: Functions;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  private readonly view: Vue;

  readonly i18n_namespace = 'components.contract-savings.personal-information.address';

  readonly error_message = i18n.tc('components.contract-savings.error_on_save_step');

  internal_step = 1;

  small_screen: null | boolean = null;

  full_address_on_ine = '';

  address_proof: File | null = null;

  confirm_address = false;

  address_form = false;

  address_proof_accept = 'image/png, image/jpeg, image/jpg';

  investment_provider_name = 'allianz';

  exists_step = false;

  step_name = 'proof_of_address';

  investment_provider_id = '';

  is_loading = true;

  uploading_file = false;

  invalid_file = false;

  readonly limit_file_size = 4 * 1024 * 1024;

  readonly minimum_file_size = 0.1;

  readonly allowed_extensions = ['image/jpeg', 'image/jpg', 'image/png'];

  document_types_items: DocumentTypeEntity[] = [];

  customer_documents: CustomerDocumentEntity[] = [];

  file_to_upload: FileDto = {
    name: uuid(),
    mime_type: '',
    file_data: '',
  }

  document_type: DocumentTypeEntity = {
    codTipoExpediente: '',
    idExpediente: '',
    idDocumento: '',
    descExpediente: '',
    codDocumento: '',
    indReverso: '',
    esObligatorio: '',
    descDocumento: '',
  }

  on_boarding_step: OnBoardingStepEntity = {
    id: uuid(),
    current_step: '',
    investment_provider_id: '',
    payload: {
      proof_of_address: {
        file_name: '',
        file_weight: '',
        file_data: '',
        was_uploaded: false,
      },
      proof_of_address_type: {
        indReverso: '',
        idDocumento: '',
        codDocumento: '',
        idExpediente: '',
        descDocumento: '',
        esObligatorio: '',
        descExpediente: '',
        codTipoExpediente: '',
      },
      is_proof_of_address_ine: false,
    },
  };

  payload = {
    complete_and_correct_address: false,
    file: {
      name: '',
      mime_type: '',
      file_data: '',
    },
  };

  rules = {
    document_type: [(value: string) => requiredRuleByKey(value, 'idDocumento')],
    full_address_on_ine: [requiredRule],
  };

  public constructor(view: any) {
    this.view = view;
  }

  loadDocumentTypes = async () => {
    try {
      this.document_types_items = await this.searchDocumentTypesQuery.execute();
    } catch (error) {
      this.messageNotifier.showErrorNotification(i18n.tc(`${this.i18n_namespace}.errors.load_document_types`));
    }
  }

  loadInvestmentsProviders = async () => {
    try {
      const investment_providers = await this.searchInvestmentProvidersQuery.execute();
      this.setInvestmentProviderId(investment_providers);
    } catch (error) {
      this.messageNotifier.showErrorNotification(i18n.tc(`${this.i18n_namespace}.errors.load_investment_providers`));
    }
  }

  loadSteps = async () => {
    try {
      const steps = await this.searchOnBoardingStepsQuery.execute(this.investment_provider_id);
      await this.verifyStep(steps);
    } catch (error) {
      this.messageNotifier.showErrorNotification(i18n.tc(`${this.i18n_namespace}.errors.load_steps`));
    }
  }

  loadCustomerDocuments = async () => {
    try {
      this.customer_documents = await this.search_customer_documents_query.execute();
    } catch (error) {
      this.messageNotifier.showErrorNotification(i18n.tc(`${this.i18n_namespace}.errors.load_customer_documents`));
    }
  }

  getFile = async (customer_document_id: string): Promise<FileEntity | null> => {
    try {
      const file = await this.get_file_query.execute(customer_document_id);
      return file;
    } catch (error) {
      this.messageNotifier.showErrorNotification(i18n.tc(`${this.i18n_namespace}.errors.load_file`));
      return null;
    }
  }

  handleAddressProof = (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      const [file] = target.files;
      if (file.size <= this.limit_file_size && file.size >= this.minimum_file_size
        && this.allowed_extensions.indexOf(file.type) > -1) {
        this.address_proof = file;
        this.invalid_file = false;
      } else {
        this.invalid_file = true;
      }
    }
  }

  get styledFileName(): string {
    if (this.address_proof) {
      return `${this.address_proof.name.slice(0, 30)}${this.address_proof.name.length > 35 ? '...' : ''}`;
    }

    return i18n.t(`${this.i18n_namespace}.address_proof.placeholder`).toString();
  }

  get styledFileSize(): string {
    if (this.address_proof) {
      return convertToLargerPossibleUnit(this.address_proof.size);
    }

    return '0 kb';
  }

  get canContinue(): boolean {
    if (this.small_screen) {
      return (this.full_address_on_ine === 'yes' || !!this.address_proof)
        && this.confirm_address
        && this.address_form
        && !this.invalid_file;
    }

    switch (this.internal_step) {
      case 1:
        return this.full_address_on_ine === 'yes' || !!this.address_proof;
      case 2:
        return this.confirm_address && this.address_form;
      default:
        return true;
    }
  }

  convertFileToBase64 = async (selected_file: File) => (
    this.functions.convert_file_to_base_64(selected_file)
  );

  convertBase64ToFile = async (base64: string, mime_type: string, file_name: string) => (
    this.functions.convert_base_64_to_file(base64, mime_type, file_name)
  );

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === this.investment_provider_name,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  verifyStep = async (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === this.step_name,
    );
    if (search_step) {
      this.exists_step = true;
      this.on_boarding_step = search_step;
      await this.setInputsDataFromStep();
    }
  };

  setInputsDataFromStep = async () => {
    this.full_address_on_ine = this.on_boarding_step.payload.is_proof_of_address_ine
      ? 'yes' : 'no';
    await this.proofOfAddressFromStep();
    this.document_type = this.on_boarding_step.payload.proof_of_address_type;
  }

  proofOfAddressFromStep = async () => {
    await this.loadCustomerDocuments();
    const proof_of_address = this.customer_documents.find(
      (item) => item.document_type.name === 'allianz_proof_of_address',
    );
    if (proof_of_address) {
      const proof_of_address_file = await this.getFile(proof_of_address.customer_document_id);
      if (proof_of_address_file) {
        this.address_proof = await this.convertBase64ToFile(
          proof_of_address_file.content,
          proof_of_address_file.mime_type,
          proof_of_address_file.name,
        );
        this.file_to_upload = {
          name: proof_of_address_file.id,
          mime_type: proof_of_address_file.mime_type,
          file_data: proof_of_address_file.content,
        };
      }
    }
  }

  setFileData = async (file: File) => {
    this.payload.file.name = file.name;
    this.payload.file.mime_type = file.type;
    const base64 = await this.convertFileToBase64(file);
    if (base64) {
      this.payload.file.file_data = base64.toString();
    }
  };

  getIneAsProofOfAddress = async () => {
    let load_ine = false;
    await this.loadCustomerDocuments();
    if (this.customer_documents.length) {
      const ine = this.customer_documents.find(
        (item) => item.document_type.name === 'ine_front',
      );
      if (ine) {
        const ine_file = await this.getFile(ine.customer_document_id);
        if (ine_file) {
          this.address_proof = await this.convertBase64ToFile(
            ine_file.content, ine_file.mime_type, ine_file.name,
          );
          this.file_to_upload.mime_type = ine_file.mime_type;
          this.file_to_upload.file_data = ine_file.content;
        }
        load_ine = true;
      }
    }
    return load_ine;
  }

  fileUploadByType = async (full_address_on_ine: boolean) => {
    this.is_loading = true;
    let file_uploaded = false;
    let loaded_ine = true;
    if (full_address_on_ine) {
      loaded_ine = await this.getIneAsProofOfAddress();
    } else {
      this.file_to_upload.mime_type = this.payload.file.mime_type;
      this.file_to_upload.file_data = this.payload.file.file_data;
    }
    file_uploaded = loaded_ine && await this.uploadFile(this.file_to_upload);
    this.is_loading = false;
    return file_uploaded;
  }

  async saveStep() {
    this.is_loading = true;
    let saved = false;
    try {
      this.on_boarding_step.current_step = this.step_name;
      this.on_boarding_step.investment_provider_id = this.investment_provider_id;
      this.on_boarding_step.payload = {
        proof_of_address: {
          file_name: this.address_proof?.name,
          file_weight: this.styledFileSize,
          file_data: this.payload.file.file_data,
          was_uploaded: true,
        },
        proof_of_address_type: this.document_type,
        is_proof_of_address_ine: this.payload.complete_and_correct_address,
      };
      if (this.exists_step) {
        delete this.on_boarding_step.customer_id;
        await this.updateOnBoardingStepCommand.execute(this.on_boarding_step);
      } else {
        await this.createOnBoardingStepCommand.execute(this.on_boarding_step);
        this.exists_step = true;
      }
      saved = true;
    } catch (error) {
      this.messageNotifier.showErrorNotification(this.error_message);
    } finally {
      this.is_loading = false;
    }
    return saved;
  }

  async uploadFile(file_dto: FileDto): Promise<boolean> {
    this.uploading_file = true;
    let uploaded_file = false;
    try {
      await this.uploadFileProofOfAddressCommand.execute(file_dto);
      uploaded_file = true;
    } catch (error) {
      this.messageNotifier.showErrorNotification(this.error_message);
    } finally {
      this.uploading_file = false;
    }
    return uploaded_file;
  }

  updateDocumentTypeOnChangeFullAddressOnIne = (full_address_on_ine: string) => {
    this.payload.complete_and_correct_address = full_address_on_ine === 'yes';
    if (full_address_on_ine === 'yes') {
      this.document_type = {
        ...this.document_types_items[0],
      };
    } else {
      this.document_type = {
        codTipoExpediente: '',
        idExpediente: '',
        idDocumento: '',
        descExpediente: '',
        codDocumento: '',
        indReverso: '',
        esObligatorio: '',
        descDocumento: '',
      };
    }
  }

  confirmAddress = (confirmation: boolean) => {
    this.confirm_address = confirmation;
  }

  prevStep = () => {
    if (
      this.small_screen
      || this.internal_step === 1
    ) {
      this.view.$emit('prevStep');
    } else {
      this.internal_step = 1;
    }
  }

  saveProofOfAddress = async () => {
    let proof_of_address_saved = false;
    const use_ine = this.full_address_on_ine === 'yes';
    const file_uploaded = await this.fileUploadByType(use_ine);
    if (file_uploaded) {
      proof_of_address_saved = await this.saveStep();
    }
    return proof_of_address_saved;
  }

  saveAddress = async () => {
    const access_to_form = this.view.$refs.address_form as any;
    const saved_step = await access_to_form.saveStep();
    return saved_step;
  }

  nextStep = async (small_device: boolean) => {
    if (!small_device) {
      if (this.internal_step === 1) {
        if (await this.saveProofOfAddress()) {
          this.internal_step = 2;
        }
      } else if (await this.saveAddress()) {
        this.view.$emit('nextStep');
      }
    } else if (await this.saveProofOfAddress()) {
      if (await this.saveAddress()) {
        this.view.$emit('nextStep');
      }
    }
  }

  initialize = async (screen_file_size: string) => {
    this.small_screen = screen_file_size === 'small';
    await this.loadDocumentTypes();
    await this.loadInvestmentsProviders();
    await this.loadSteps();
    this.is_loading = false;
  }
}
